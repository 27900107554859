<template>
  <div class="container">
    <div class="navbar">
      <div class="menu">
        <h3 class="logo">Coding<span>Beast</span></h3>
        <div class="hamburger-menu">
          <div class="bar"></div>
        </div>
      </div>
    </div>
    <div class="main-container">
      <div class="main">
        <header>
          <div class="overlay">
            <h2 class="title">Future is here</h2>
            <p class="description">“All our dreams can come true,
              if we have the courage to pursue them.”
            </p>
            <a href="#" class="btn">Read More</a>
          </div>
        </header>
      </div>
      <div class="shadow one"></div>
      <div class="shadow two"></div>
    </div>
    <div class="links">
      <ul>
        <li class="active">
          <a href="#" style="--i: 0.05s">Home</a>
        </li>
        <li>
          <a href="#" style="--i: 0.1s">About</a>
        </li>
        <li>
          <a href="#" style="--i: 0.15s">Contact</a>
        </li>
        <li>
          <a href="#" style="--i: 0.2s">Service</a>
        </li>
        <li>
          <a href="#" style="--i: 0.25s">Portfolio</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "MenuNr2",


  mounted() {

    const hamburger_menu = document.querySelector(".hamburger-menu");

    const container = document.querySelector(".container");

    hamburger_menu.addEventListener("click", () => {
      container.classList.toggle("active");
    })

  }
}
</script>

<style scoped>


@import url('https://fonts.googleapis.com/css2?family=Recursive:wght@400;500;600;700;800;900&display=swap');

*{ padding:0; margin: 0; box-sizing: border-box; }

body{ font-family: 'Recursive', sans-serif; overflow: hidden; }

.container{ max-height: 100vh; max-width: 100vw; background-color: #131414;
  background-image: linear-gradient(135deg, #131414 0%, #000000
  100%); transform-style: preserve-3d; overflow: hidden;
}

.navbar{ position: fixed; top: 0; left: 0; width: 100%; z-index: 10;
  height: 5rem;
}

.menu{ max-width: 72rem; width: 100%; height: 100%; margin: 0 auto;
  padding: 0 2rem; display: flex; justify-content: space-between;
  align-items: center; color: #fff;
}

.logo{
  font-size: 1.8rem; font-weight: 600; text-transform: uppercase;
  letter-spacing: 2px; line-height: 4rem; margin-top: 20px;
}

.logo span{ font-size: 1.8rem; margin-left: 5px; color: #e20f2f; }

.hamburger-menu{ height: 4rem; width: 3rem; cursor: pointer;
  display: flex; align-items: center; justify-content: flex-end; }

.bar{ width: 1.9rem; height: 1.5px; border-radius: 2px;
  background-color: #eee; transition: 0.5s; position: relative; }

.bar::before, .bar::after{ content: ""; position: absolute;
  width: inherit; height: inherit; background-color: #eee;
  transition: 0.5s; }

.bar::before{ transform: translateY(-9px); }

.bar::after{ transform: translateY(9px); }

.main-container{ overflow: hidden; }

.main{ position: relative; width: 100%; left: 0; z-index: 5;
  overflow: hidden; transform-origin: left;
  transform-style: preserve-3d; transition: 0.5s;
}

header{ min-height: 100vh; width: 100%;
  background: url("https://i.postimg.cc/0Q09GwLQ/image.jpg") no-repeat top center / cover;
  position: relative;
}

.overlay{ position: absolute; width: 100%; height: 100%; top: 0;
  left: 0; background-color: rgba(0, 0, 0, 0.712);
  display: flex; justify-content: center; align-items: center;
  flex-direction: column; color: #fff;
}

.inner{ max-width: 35rem; text-align: center; color: #fff;
  padding: 0 2rem;
}

.title{ font-size: 3rem; }

.description{ margin: 10px 0; text-align: center; width: 50%;
  font-size: 1.5rem;
}

.btn{ margin-top: 1rem; padding: 0.6rem 1.8rem;
  background-color: #e20f2f; border: none; border-radius: 25px;
  color: #fff; text-transform: uppercase; cursor: pointer;
  text-decoration: none;
}

.container.active .bar{ transform: rotate(360deg);
  background-color: transparent;
}

.container.active .bar::before{
  transform: translateY(0) rotate(45deg);
}

.container.active .bar::after{
  transform: translateY(0) rotate(-45deg);
}

.container.active .main{
  animation: main-animation 0.5s ease; cursor: pointer;
  transform: perspective(1300px) rotateY(20deg) translateY(10px)
  translateZ(310px) scale(0.5);
}

@keyframes main-animation{
  from{ transform: translate(0); }
  to{
    transform: perspective(1300px) rotateY(20deg) translateY(10px)
    translateZ(310px) scale(0.5);
  }
}

.links{ position: absolute; width: 30%; right: 0; top: 0;
  height: 100vh; z-index: 2; overflow: hidden;
  display: flex; justify-content: flex-start; align-items: center;
  margin-left: 10px;
}

ul{ list-style: none; }

ul li.active a{ color: #e20f2f; }

.links a{ text-decoration: none; color: #eee; padding: 0.7rem 0;
  display: inline-block; font-size: 1.8rem; font-weight: 300;
  text-transform: uppercase; letter-spacing: 1px; transition: 0.3s;
  opacity: 0; transform: translateY(10px);
  animation: hide 0.5s forwards ease;
}

.links a:hover{ color: #e20f2f; }

.container.active .links a{
  animation: appear 0.5s forwards ease var(--i);
}

@keyframes appear{
  from { opacity: 0; transform: translateY(10px); }
  to{ opacity: 1; transform: translateY(0px); }
}

@keyframes hide{
  from { opacity: 1; transform: translateY(0px); }
  to{ opacity: 0; transform: translateY(10px); }
}

.shadow{ position: absolute; width: 100%; height: 100vh; top: 0;
  left: 0; transform-style: preserve-3d; transform-origin:left;
  transition: 0.5s; background-color: #fff; }

.shadow.one{ z-index: -1; opacity: 0.15; }

.shadow.two{ z-index: -2; opacity: 0.1; }

.container.active .shadow.one{
  animation: shadow-one 0.6s ease-out;
  transform: perspective(1300px) rotateY(20deg) translateY(10px)
  translateZ(215px) scale(0.5);
}

@keyframes shadow-one{
  0%{
    transform: translate(0);
  }
  5%{
    transform: perspective(1300px) rotateY(20deg) translateY(10px)
    translateZ(310px) scale(0.5);
  }
  100%{
    transform: perspective(1300px) rotateY(20deg) translateY(10px)
    translateZ(215px) scale(0.5);
  }
}

.container.active .shadow.two{
  animation: shadow-two 0.6s ease-out;
  transform: perspective(1300px) rotateY(20deg) translateY(10px)
  translateZ(120px) scale(0.5);
}

@keyframes shadow-two{
  0%{
    transform: translate(0);
  }
  20%{
    transform: perspective(1300px) rotateY(20deg) translateY(10px)
    translateZ(310px) scale(0.5);
  }
  100%{
    transform: perspective(1300px) rotateY(20deg) translateY(10px)
    translateZ(120px) scale(0.5);
  }
}

.container.active .main:hover + .shadow.one{
  transform: perspective(1300px) rotateY(20deg) translateY(10px)
  translateZ(230px) scale(0.5);
}
.container.active .main:hover{
  transform: perspective(1300px) rotateY(20deg) translateY(10px)
  translateZ(340px) scale(0.5);
}
</style>